export enum EventType {
  ACTIVE_TAB = 'ACTIVE_TAB',
  LOGOUT = 'LOGOUT',
  ACTIVE_REFRESH = 'ACTIVE_REFRESH',
  REMOVE_TAB = 'REMOVE_TAB',
}

export type AuthWorkerEvent = {
  eventType: EventType;
  accountId?: string;
  tokenRefreshInterval?: number;
};

export type AuthMapEntry = {
  intervalId: ReturnType<typeof setInterval> | null;
  ports: MessagePort[];
};
