import { convertMinutesToMilliSeconds } from './Utils';

export enum AuthStorageKeys {
  authConfig = 'authConfig',
  accountId = 'accountId',
  accessTokenByAccountId = 'accessTokenByAccountId',
}

const defaultIdleTimeout = -1; // no idle timeout was set, do not logout the user
export const getTimeoutFromAuthConfig = ({ key, defaultValue }: { key: string; defaultValue: number }) => {
  const authConfig = JSON.parse(localStorage.getItem(AuthStorageKeys.authConfig) || 'null');
  const timeoutValue = authConfig?.[key];
  // if there is timeout value of -1, it means the timeout is disabled when key is logoutInactivityTimeout
  if (timeoutValue === defaultIdleTimeout) {
    return defaultIdleTimeout;
  }

  return timeoutValue ? convertMinutesToMilliSeconds(timeoutValue) : convertMinutesToMilliSeconds(defaultValue);
};

export const getAccessTokenTimeout = () => {
  const timeoutInSeconds = getTimeoutFromAuthConfig({ key: 'accessTokenTimeoutInMinutes', defaultValue: 5 }); // default is 5 minutes
  return timeoutInSeconds - 30000; // remove half minute to prevent race condition
};

export const setAccountTokenInLs = ({ token, accountId }) => {
  // Check is required to local authentication
  if (accountId) {
    const accessTokenByAccountIdValue = JSON.parse(localStorage.getItem(AuthStorageKeys.accessTokenByAccountId) || '{}');
    localStorage.setItem(AuthStorageKeys.accessTokenByAccountId, JSON.stringify({ ...accessTokenByAccountIdValue, [accountId]: token }));
  }
};
