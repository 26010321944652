import React from 'react';
import { rebranding } from '../../rebranding';
import { APP_PATHS, ROLES } from '../../types';
import { App } from '../../types/sideBar.types';
import { ReactComponent as AppAlerts } from '../../assets/apps/Alerts.svg';
import { ReactComponent as Assets } from '../../assets/apps/Assets.svg';
import { ReactComponent as Findings } from '../../assets/apps/Findings.svg';
import { ReactComponent as Incident } from '../../assets/apps/Incident.svg';
import { ReactComponent as Risk360 } from '../../assets/apps/Risk360.svg';
import { ReactComponent as AppAlertsNew } from '../../assets/apps2/Alerts.svg';
import AlertsFull from '../../assets/apps2/AlertsFull.svg';
import { ReactComponent as AssetsNew } from '../../assets/apps2/Assets.svg';
import AssetsFull from '../../assets/apps2/AssetsFull.svg';
import { ReactComponent as FindingsNew } from '../../assets/apps2/Findings.svg';
import FindingsFull from '../../assets/apps2/FindingsFull.svg';
import { ReactComponent as IncidentNew } from '../../assets/apps2/Incidents.svg';
import IncidentsFull from '../../assets/apps2/IncidentsFull.svg';
import { ReactComponent as Risk360New } from '../../assets/apps2/Risk360.svg';
import Risk360Full from '../../assets/apps2/Risk360Full.svg';

export const APPBAR_HEIGHT = 56;

export const appsOptions: App[] = [
  {
    id: APP_PATHS.VULNERABILITIES,
    name: 'Vulnerabilities',
    description: 'Vulnerability Prioritization Tool',
    icon: rebranding ? <FindingsNew /> : <Findings />,
    imageFull: rebranding ? FindingsFull : undefined,
    iconFull: <img src={FindingsFull} alt="Vulnerabilities" style={{ width: 24, height: 24 }} />,
    backgroundColor: theme =>
      rebranding ? theme.palette.gradients.yellow : 'linear-gradient(45deg, rgba(241, 178, 9, 0.35) 0%, rgba(252, 219, 53, 0.35) 100%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    id: APP_PATHS.DETECTIONS,
    name: 'Detections',
    description: 'Event Detections',
    icon: rebranding ? <AppAlertsNew /> : <AppAlerts />,
    imageFull: rebranding ? AlertsFull : undefined,
    iconFull: <img src={AlertsFull} alt="Detections" style={{ width: 24, height: 24 }} />,
    backgroundColor: theme =>
      rebranding ? theme.palette.gradients.orange : 'linear-gradient(271deg, rgba(241, 119, 1, 0.25) 0%, rgba(250, 209, 169, 0.25) 100%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.SIEM_USER, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.INCIDENTS,
    name: 'Incidents',
    description: 'Incident Management',
    icon: rebranding ? <IncidentNew /> : <Incident />,
    imageFull: rebranding ? IncidentsFull : undefined,
    iconFull: <img src={IncidentsFull} alt="Incidents" style={{ width: 24, height: 24 }} />,
    backgroundColor: theme =>
      rebranding
        ? theme.palette.gradients.purple
        : 'linear-gradient(271.19deg, rgba(148, 139, 255, 0.3) 18.91%, rgba(212, 211, 255, 0.3) 141.41%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.RISK360,
    name: 'Risk360',
    description: 'Risk360',
    icon: rebranding ? <Risk360New /> : <Risk360 />,
    imageFull: rebranding ? Risk360Full : undefined,
    iconFull: <img src={Risk360Full} alt="Risk360" style={{ width: 24, height: 24 }} />,
    backgroundColor: theme =>
      rebranding
        ? theme.palette.gradients.primary
        : 'linear-gradient(271deg, rgba(85, 136, 251, 0.35) 18.91%, rgba(201, 220, 255, 0.35) 141.41%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.CAASM,
    name: 'CAASM',
    description: 'Cyber Asset Management',
    icon: rebranding ? <AssetsNew /> : <Assets />,
    imageFull: rebranding ? AssetsFull : undefined,
    iconFull: <img src={AssetsFull} alt="CAASM" style={{ width: 24, height: 24 }} />,
    backgroundColor: theme =>
      rebranding ? theme.palette.gradients.blue : 'linear-gradient(271deg, rgba(83, 168, 255, 0.35) 0%, rgba(196, 225, 255, 0.35) 100%)',
    rolesPermission: [ROLES.RND_ADMIN], // TODO: deprecated
  },
];
