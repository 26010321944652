import { getAccessTokenTimeout } from '../utils/localStorageAuthUtils.utils';
import { EventType } from './authWorker.types';
import SharedWorker from './sharedWorkerPolyfill';

let sharedWorker: SharedWorker | null = null;

const getSharedAuthWorker = () => {
  if (!sharedWorker) {
    sharedWorker = new SharedWorker(new URL('./sharedAuthWorker.ts', import.meta.url), { type: 'module', name: 'Avalor Auth Worker' });
    sharedWorker.start();
  }

  return sharedWorker;
};

export const sharedWorkerService = {
  onMessage: callback => {
    getSharedAuthWorker().onmessage = event => {
      callback(event.data);
    };
  },

  terminate: () => getSharedAuthWorker().close(),

  notifyWorkerAboutTokenRefresh: (accountId: string) =>
    getSharedAuthWorker().postMessage({
      accountId,
      tokenRefreshInterval: getAccessTokenTimeout(),
      eventType: EventType.ACTIVE_REFRESH,
    }),

  notifyWorkerOnActiveTabEvent: (accountId: string) =>
    getSharedAuthWorker().postMessage({ accountId, tokenRefreshInterval: getAccessTokenTimeout(), eventType: EventType.ACTIVE_TAB }),

  notifyWorkerOnUnloadEvent: (accountId: string) => getSharedAuthWorker().postMessage({ accountId, eventType: EventType.REMOVE_TAB }),

  notifyWorkerOnInactiveTabEvent: (accountId: string) => getSharedAuthWorker().postMessage({ accountId, eventType: EventType.REMOVE_TAB }),

  notifyWorkerOnSwitchAccountEvent: (accountId: string) =>
    getSharedAuthWorker().postMessage({ accountId, eventType: EventType.REMOVE_TAB }),

  notifyWorkerOnLogoutEvent: () => getSharedAuthWorker().postMessage({ eventType: EventType.LOGOUT }),
};

export default sharedWorkerService;
