import React from 'react';
import { Avatar, Box, capitalize, Divider, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import cronstrue from 'cronstrue';
import { format as d3Format } from 'd3';
import { format, isDate } from 'date-fns';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { flex } from '../components/AvThemeProvider.tsx';
import { rebranding } from '../rebranding.ts';
import { EMPTY_STRING, FormatterTypes, NO_VALUE } from '../views/CustomDashboards/constants.ts';
import { TimeBucketToDateGap } from '../views/Reports/types.ts';
import { DateFormats } from './date.utils.ts';
import { ReactComponent as ArrowDown } from '../assets/Arrow Down.svg';
import { ReactComponent as AssetCloudNew } from '../assets/Asset_Cloud_new.svg';
import { ReactComponent as AssetCloud } from '../assets/Asset_Cloud.svg';
import { ReactComponent as AssetDatabaseNew } from '../assets/Asset_DataBase_new.svg';
import { ReactComponent as AssetDatabase } from '../assets/Asset_Database.svg';
import { ReactComponent as AssetDefaultNew } from '../assets/Asset_Default_new.svg';
import { ReactComponent as AssetDefault } from '../assets/Asset_Default.svg';
import { ReactComponent as AssetFilesNew } from '../assets/Asset_Files_new.svg';
import { ReactComponent as AssetFiles } from '../assets/Asset_Files.svg';
import { ReactComponent as AssetHostNew } from '../assets/Asset_Host_new.svg';
import { ReactComponent as AssetHost } from '../assets/Asset_Host.svg';
import { ReactComponent as AssetImageNew } from '../assets/Asset_Image_new.svg';
import { ReactComponent as AssetImage } from '../assets/Asset_Image.svg';
import { ReactComponent as AssetMobile } from '../assets/Asset_mobile_device.svg';
import { ReactComponent as AssetNetDeviceNew } from '../assets/Asset_NetDevice_new.svg';
import { ReactComponent as AssetNetDevice } from '../assets/Asset_NetDevice.svg';
import { ReactComponent as AssetRepoNew } from '../assets/Asset_Repo_new.svg';
import { ReactComponent as AssetRepo } from '../assets/Asset_Repo.svg';
import { ReactComponent as AssetUserNew } from '../assets/Asset_User_new.svg';
import { ReactComponent as AssetUser } from '../assets/Asset_User.svg';
import { ReactComponent as Maximize } from '../assets/Maximize.svg';
import { ReactComponent as Minimize } from '../assets/Minimize.svg';

const maximizeIcon = <Maximize style={{ width: 18, height: 18 }} />;
const minimizeIcon = <Minimize style={{ width: 18, height: 18 }} />;

export const commonFormStyles = {
  horizontalForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    '& .switchToggle': {
      marginLeft: '8px',
      // alignSelf: ({ error }) => (error ? '' : 'end'),
      height: '40px',
      alignSelf: 'end',
      pt: '24px',
    },
  },
};

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const generateID = () => Math.random().toString(36).slice(-6);

export const generateUUID = () => crypto.randomUUID();

export const userRoleMap = {
  RND_ADMIN: 'Avalor Admin',
  ADMIN: 'Administrator',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
  NO_ACCESS: 'No Access',
  SIEM: 'SIEM',
  PRE_POV: 'Pre POV',
  SYS_ADMIN: 'Sys Admin',
};

export const ExpandCard = styled(({ expand, fullScreen, toggleFullScreen, ...other }) => {
  const onClickResize = e => {
    e.stopPropagation();
    toggleFullScreen();
  };

  return (
    <Box sx={{ ...flex.row, gap: 2 }}>
      {toggleFullScreen ? (
        <IconButton sx={{ height: 18 }} onClick={onClickResize}>
          {fullScreen ? minimizeIcon : maximizeIcon}
        </IconButton>
      ) : null}
      {!fullScreen && (
        <>
          <Divider flexItem orientation="vertical" sx={{ height: 22 }} />
          <IconButton aria-expanded={expand} aria-label="show more" {...other}>
            <ArrowDown style={iconSize(18)} />
          </IconButton>
        </>
      )}
    </Box>
  );
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  padding: 0,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const getMitreLink = techniqueId => `https://attack.mitre.org/techniques/${techniqueId}`;

export const cellContentStyle = {
  display: '-webkit-box',
  WebkitLineClamp: '5',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  wordBreak: 'break-all',
  whiteSpace: 'normal',
};

export const arrayEquals = (a, b, compareItems = (itemA, itemB) => itemA === itemB) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => compareItems(val, b[index]));

export const ellipsis = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const noop = () => {};
export const emptyObject = {};
export const emptyArray = [];

export const capitalizeSentence = sentence => sentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
export const camelCaseToTitleCase = str => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
export const formatStartEachWordWithCapitalLetter = sentence =>
  sentence ? capitalizeSentence(sentence.replace(/_/g, ' ').toLowerCase()) : sentence;

export function FoundCountResults({ label, count, loading, style, height }) {
  return loading ? (
    <Skeleton height={height} width={160} />
  ) : (
    <Typography sx={{ color: theme => theme.palette.colors.neutrals[800], ...style }}>
      <strong>{count.toLocaleString()}</strong> {label} found
    </Typography>
  );
}

FoundCountResults.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.bool,
  style: PropTypes.shape(),
};
FoundCountResults.defaultProps = {
  count: 0,
  height: 21,
  loading: undefined,
  style: {},
};

export const mapValueCapitalizeFunc = v =>
  v
    .split('_')
    .map(s => `${capitalize(s.toLowerCase())}`)
    .join(' ');

export const uniqBy = (arr, predicate) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

  const pickedObjects = arr
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item);

      if (key === undefined) {
        return map;
      }

      return map.has(key) ? map : map.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
};

export function CollapseArrow({ isExpanded }) {
  const theme = useTheme();
  return (
    <ArrowDown
      style={{
        transform: `rotate(${isExpanded ? 0 : -90}deg)`,
        transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shorter }),
      }}
    />
  );
}

CollapseArrow.propTypes = { isExpanded: PropTypes.bool };
CollapseArrow.defaultProps = { isExpanded: false };

export function stringToColor(string, colors) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  let color = '#';
  // choose random color
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  // choose a color from the list
  const colorIndex = hash % colors.length;
  return rebranding ? colors[colorIndex] : color;
}

export function Initials({ name, size }) {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        backgroundColor: stringToColor(name, theme.palette.avatarColors),
        textTransform: 'uppercase',
        fontWeight: 600,
        ...(size === 'small' ? { width: 30, height: 30, fontSize: 13 } : size === 'xSmall' ? { width: 24, height: 24, fontSize: 12 } : {}),
      }}>
      {name.split(' ')[0]?.[0]}
      {name.split(' ')[1]?.[0]}
    </Avatar>
  );
}

Initials.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'small', 'xSmall']),
};
Initials.defaultProps = {
  size: 'medium',
};

export const refetchingStyle = {
  filter: 'blur(3px) grayscale(1)',
  opacity: 0.5,
  pointerEvents: 'none',
};

export const formDisabledStyle = {
  filter: 'opacity(0.5)',
  pointerEvents: 'none',
};

export const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getIfValidStringifiedObject = string => {
  try {
    const obj = JSON.parse(decodeURIComponent(string));
    if (!isObject(obj)) {
      return false;
    }
    return obj;
  } catch (_) {
    return false;
  }
};

export function arrayToCsv(data) {
  return data
    .map(row =>
      row
        .map(v => `"${String(v).replaceAll('"', '""')}"`) // quote it
        .join(',')
    )
    .join('\r\n'); // comma-separated rows starting on new lines
}

export function downloadBlob(content, contentType, fileName) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
}

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const truncateDecimal = (num, digits) => Math.trunc(num * 10 * digits) / (10 * digits);
export const getPercent = num => `${Math.round(num * 100) / 100}%`;

export const setNestedValue = (path, obj, value) => {
  const setValue = (string, obj, value) => {
    const [current, ...rest] = string.split('.');
    // eslint-disable-next-line no-unused-expressions,no-param-reassign
    rest.length >= 1 ? setValue(rest.join('.'), (obj[current] = obj[current] || {}), value) : (obj[current] = value);
    return obj;
  };
  return setValue(path, structuredClone(obj), value);
};
export const getNestedValue = (string, obj) => {
  const [current, ...rest] = string.split('.');
  if (rest.length > 0) {
    // eslint-disable-next-line no-unused-expressions,no-param-reassign
    return getNestedValue(rest.join('.'), (obj[current] = obj[current] || {}));
  }
  return obj[current];
};

export const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
export const abbreviateNumber = val => d3Format(val < 1 && val > 0 ? '.2f' : '.3~s')(val).replace('G', 'B');
export const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) =>
  structuredClone({
    [newProp]: old,
    ...others,
  });

export const getCleanNumberInputValue = value => (!value.length || Number.isNaN(+value) ? null : parseFloat(value));
export const isDeepEqual = (object1 = {}, object2 = {}, ignoreFields = []) => {
  const objKeys1 = Object.keys(object1).filter(key => !ignoreFields.includes(key));
  const objKeys2 = Object.keys(object2).filter(key => !ignoreFields.includes(key));

  if (objKeys1.length !== objKeys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObjectOrArray(value1) && isObjectOrArray(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export const isEquals = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return arrayEquals(a, b);
  }
  if (typeof a === 'object' && typeof b === 'object') {
    return isDeepEqual(a, b);
  }
  return a === b;
};

const isObjectOrArray = object => object != null && typeof object === 'object';
export const isObject = object => object !== null && typeof object === 'object' && !Array.isArray(object);

export const generateOption = value => ({ title: value, value });

export const getMatchStringValuesInExpression = expression => {
  if (expression.includes('"')) {
    const matches = expression.match(/"([^"]*)"/g);
    return matches ? matches.map(m => m.slice(1, -1)) : [];
  }
  return [expression];
};
const mapValueFunc = v => (typeof v === 'string' ? `'${v.replaceAll("'", "''")}'` : 'null');
export const generateStringList = (arr = []) => arr.map(mapValueFunc).join(', ');

export const flattenKeys = obj => {
  const result = [];

  function recurse(obj, currentPath) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in obj) {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      if (typeof obj[key] === 'object' && !isDate(obj[key])) {
        recurse(obj[key], newPath);
      } else {
        result.push(newPath);
      }
    }
  }

  recurse(obj, '');
  return result;
};
export const flattenObject = ({ obj, prefix = '', res = {}, shouldFlattenArray = true }) =>
  Object.entries(obj).reduce((r, [key, val]) => {
    const k = prefix ? (Array.isArray(obj) ? `${prefix}[${key}]` : `${prefix}.${key}`) : key;
    if (val && typeof val === 'object' && (shouldFlattenArray || !Array.isArray(val))) {
      const isEmptyArray = Array.isArray(val) && !val.length;
      if (isEmptyArray) {
        res[k] = JSON.stringify(val);
      } else {
        flattenObject({ obj: val, prefix: `${k}`, res: r, shouldFlattenArray });
      }
    } else {
      res[k] = val;
    }
    return r;
  }, res);

export const prettyFieldName = field =>
  field
    .split(/[._]/)
    .map(w => (['ip', 'os', 'cve', 'cwe', 'fqdn', 'id', 'url', 'sla'].includes(w) ? w.toUpperCase() : capitalize(w)))
    .join(' ');

export const sanitize = dirty => DOMPurify.sanitize(dirty);
export function SanitizeHTML({ html, ...props }) {
  return <Box sx={styleHTML} dangerouslySetInnerHTML={{ __html: sanitize(html) }} {...props} />;
}

SanitizeHTML.propTypes = {
  html: PropTypes.string.isRequired,
};

const styleHTML = {
  'pre, code': {
    backgroundColor: ({ palette }) => palette.colors.neutrals[200],
    color: 'inherit',
  },
  pre: {
    whiteSpace: 'pre-wrap',
    my: '5px',
    padding: '5px 10px',
  },
  code: {
    fontSize: 13,
    padding: '2px 4px',
  },
  a: {
    color: ({ palette }) => palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export function removeNodeFromObject(obj, node) {
  // Check if the object is null or not an object
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    // Create a new array without the node
    return obj.filter(item => item !== node).map(item => removeNodeFromObject(item, node));
  }
  // Create a new object without the node
  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== node) {
      newObj[key] = removeNodeFromObject(value, node);
    }
  });

  return newObj;
}

export const orderByKey = (array, key, isAsc = true) =>
  [...array].sort((a, b) => {
    const valA = (typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]) ?? '';
    const valB = (typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]) ?? '';
    if (valA < valB) {
      return isAsc ? -1 : 1;
    }
    if (valA > valB) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });

export function moveElementToEnd(array, element) {
  const index = array.indexOf(element);

  if (index !== -1) {
    return [...array.filter((el, i) => i !== index), element];
  }

  return array;
}

export const getFieldDisplayName = (displayName, group) => `${group} ${displayName}`;

export const iconSize = (size = 20) => ({ width: size, height: size });

export const dotStyle = (color, size = 10) => ({
  width: size,
  height: size,
  borderRadius: '50%',
  background: color,
});

export const isNullOrUndefined = value => value === null || value === undefined;

export const isArrayofArrays = arr => Array.isArray(arr) && arr.every(element => Array.isArray(element)) && arr.length;

const getFilteredList = (list, item, filterKey) =>
  list.filter(v => (filterKey === undefined ? v !== item : v[filterKey] !== item[filterKey]));

export const rearrange = (list, item, newIndex, filterKey, hasMoved) => {
  const filteredList = getFilteredList(list, item, filterKey);
  const newItem = filterKey === undefined ? item : { ...item, hidden: hasMoved ? !item.hidden : item.hidden };
  if (newIndex === list.length - (hasMoved ? 0 : 1)) {
    return [...filteredList, newItem];
  }
  return (hasMoved ? list : filteredList).reduce(
    (arr, el, index) =>
      [...arr, index === newIndex && newItem, (filterKey === undefined ? el !== item : el[filterKey] !== item[filterKey]) && el].filter(
        d => d
      ),
    []
  );
};

export const rearrangeObject = (obj, order) => ({
  ...order.reduce((acc, key) => {
    if (key in obj) {
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {}),
  ...obj,
});

export const isJSON = value => typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isJsonAndNotEmpty = value => isJSON(value) && Object.keys(value).length !== 0;

export const getEscapedValue = value => (['boolean', 'number'].includes(typeof value) ? value : value?.replaceAll("'", "''") || null);

export const getEscapedValueForSQLLike = value => (typeof value === 'string' ? value.replace(/[_%]/g, '\\$&') : value);

export const numToFixed = num => {
  const parsedNum = parseFloat(num);
  return Number.isNaN(parsedNum) ? num : parsedNum % 1 === 0 ? parsedNum.toLocaleString() : abbreviateNumber(num);
};

export function mergeObjects(obj1, obj2) {
  return Object.keys(obj2).reduce(
    (merged, key) => {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
        // If the value in obj2 is an object and not null or an array, recursively merge it
        return { ...merged, [key]: mergeObjects(merged[key] || {}, obj2[key]) };
      }
      // Otherwise, assign the value from obj2 to the merged object
      return { ...merged, [key]: obj2[key] };
    },
    { ...obj1 }
  );
}

export function extractKeyValues(obj, keyToExtract, resultArray = []) {
  if (typeof obj !== 'object' || obj === null) {
    return resultArray;
  }

  if (obj[keyToExtract] !== undefined) {
    resultArray.push(obj[keyToExtract]);
  }

  Object.values(obj).forEach(value => {
    if (typeof value === 'object') {
      extractKeyValues(value, keyToExtract, resultArray);
    }
  });

  return resultArray;
}

export function recursiveKeyUpdate(obj, targetKey, targetValuePredicate, getNewValue) {
  if (Array.isArray(obj)) {
    return obj.map(item => recursiveKeyUpdate(item, targetKey, targetValuePredicate, getNewValue));
  }

  if (typeof obj !== 'object' || obj[targetKey] === null) {
    return obj;
  }

  const updatedObj = {};
  if (obj) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (key === targetKey && targetValuePredicate(value)) {
        updatedObj[key] = getNewValue(value);
      } else {
        updatedObj[key] = recursiveKeyUpdate(value, targetKey, targetValuePredicate, getNewValue);
      }
    });
  }

  return updatedObj;
}

export function getFieldName(str = '') {
  const dotIndex = str.lastIndexOf('.');
  return dotIndex === -1 ? str : str.slice(dotIndex + 1);
}

export const sqlFormatter = (sqlString = '') => sqlString.replaceAll('\n', ' ');

export const sortByStringField = (arr, field) => {
  arr.sort((a, b) => {
    const stringA = a[field].toLowerCase();
    const stringB = b[field].toLowerCase();

    return stringA.localeCompare(stringB); // Use localeCompare for case-insensitive sorting
  });

  return arr;
};

const assetStyle = { height: 24, width: 24, aspectRatio: 1, alignSelf: 'center' };
const assetCloud = rebranding ? <AssetCloudNew style={assetStyle} /> : <AssetCloud style={assetStyle} />;
const assetHost = rebranding ? <AssetHostNew style={assetStyle} /> : <AssetHost style={assetStyle} />;
const assetDefault = rebranding ? <AssetDefaultNew style={assetStyle} /> : <AssetDefault style={assetStyle} />;
const assetDatabase = rebranding ? <AssetDatabaseNew style={assetStyle} /> : <AssetDatabase style={assetStyle} />;
const assetImage = rebranding ? <AssetImageNew style={assetStyle} /> : <AssetImage style={assetStyle} />;
const assetMoblie = <AssetMobile style={assetStyle} />;
const assetUser = rebranding ? <AssetUserNew style={assetStyle} /> : <AssetUser style={assetStyle} />;
const assetRepo = rebranding ? <AssetRepoNew style={assetStyle} /> : <AssetRepo style={assetStyle} />;
const assetFiles = rebranding ? <AssetFilesNew style={assetStyle} /> : <AssetFiles style={assetStyle} />;
const assetNewDevice = rebranding ? <AssetNetDeviceNew style={assetStyle} /> : <AssetNetDevice style={assetStyle} />;
const ASSET_TYPE_ICON_MAP = {
  'Windows Server': assetCloud,
  'Linux Server': assetCloud,
  Workstation: assetHost,
  'Mobile Device': assetMoblie,
  Unknown: assetDefault,
  Default: assetDefault,
  Storage: assetDatabase,
  Host: assetHost,
  Containers: assetDefault,
  Image: assetImage,
  Profile: assetUser,
  VPN: assetCloud,
  'Web Application': assetCloud,
  Container: assetCloud,
  Hypervisor: assetHost,
  Linux: assetHost,
  Unix: assetHost,
  'Windows Workstation': assetHost,
  'Mac Workstation': assetHost,
  'Unix Server': assetHost,
  'Virtual Machine': assetHost,
  Nutanix: assetHost,
  Mainframe: assetHost,
  AIX: assetHost,
  Database: assetDatabase,
  Repository: assetRepo,
  SAN: assetFiles,
  NAS: assetFiles,
  Firewall: assetNewDevice,
  'IoT Device': assetNewDevice,
  'Load Balancer': assetNewDevice,
  'Networking Device': assetNewDevice,
  Printer: assetNewDevice,
};

export const getAssetTypeIcon = assetType => ASSET_TYPE_ICON_MAP[assetType] || ASSET_TYPE_ICON_MAP.Default;

export const serializeSortItem = ({ property, isAsc }) => `${property}__${isAsc ? 'ASC' : 'DESC'}`;
export const deserializeSortItem = item => ({ property: item.split('__')[0], isAsc: item.split('__')[1] === 'ASC' });
export const isFieldEmpty = field => field === null || field === undefined || field === '';

export const cronExpressionToString = expression => {
  try {
    return cronstrue.toString(expression);
  } catch (error) {
    return '';
  }
};

export const formatCronValue = val => val?.slice(2).replaceAll('?', '*');

export const isValidJsonString = val => {
  try {
    return isJSON(JSON.parse(val));
  } catch (error) {
    return false;
  }
};

const is100PercentValue = (key, series) => series.some(({ dataKey, is100Percent }) => dataKey === key && is100Percent);

export const normalize100PercentData = (data, series) =>
  data.map(entry => {
    const total = Object.entries(entry).reduce(
      (acc, [key, value]) => acc + (typeof value === 'number' && is100PercentValue(key, series) ? value : 0),
      0
    );
    return Object.entries(entry).reduce((acc, [key, value]) => {
      acc[key] =
        typeof value === 'number' && is100PercentValue(key, series) && total
          ? (Math.floor((value / total) * 10000) / 100).toFixed(2)
          : value;
      return acc;
    }, {});
  });

export const EMAIL_REGEX_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isValidEmail = email => String(email).toLowerCase().match(EMAIL_REGEX_PATTERN);
export const getNetworkErrorCode = error =>
  error?.networkError?.statusCode || error?.status || error?.graphQLErrors?.[0]?.extensions?.status || error?.[0]?.extensions?.status;

export const generateInnerDefaultFormatter = ({
  value,
  type,
  size = '',
  fieldName = '',
  isFormatNull = false,
  shouldDisplayNull = false,
}) => {
  if (isFormatNull) {
    if (shouldDisplayNull) {
      if (value === '') {
        return EMPTY_STRING;
      }

      if (value === null || value === 'null') {
        return NO_VALUE;
      }
    }
  } else if (shouldDisplayNull && (value === null || value === '')) {
    return 'null';
  }

  switch (type) {
    case 'bool':
      return value?.toString();
    case 'number':
      return value && value.toLocaleString();
    case 'percentage':
      return isNullOrUndefined(value) ? '' : `${getPercent(value)}`;
    case 'date':
      return value && new Date(value).toLocaleString();
    case FormatterTypes.historicDate:
      return value && format(new Date(value), DateFormats[TimeBucketToDateGap[fieldName]] || 'MMM');
    case 'array':
      return Array.isArray(value)
        ? value.length > 0 && typeof value[0] === 'object'
          ? JSON.stringify(value)
          : value.join(', ')
        : value
          ? [value].join(', ')
          : '';
    case 'json':
      return JSON.stringify(value);
    case 'loading':
      return <Skeleton variant="text" height={size === 'tiles' ? 32 : 23} width={value} />;
    case 'string':
      return value;
    default: {
      return value?.toString();
    }
  }
};

export const alphaSolid = (rgbHex, a, bgHex = '#FFFFFF') => {
  // Parse the RGB components
  const r = parseInt(rgbHex.slice(1, 3), 16);
  const g = parseInt(rgbHex.slice(3, 5), 16);
  const b = parseInt(rgbHex.slice(5, 7), 16);

  // Parse the background RGB components
  const br = parseInt(bgHex.slice(1, 3), 16);
  const bg = parseInt(bgHex.slice(3, 5), 16);
  const bb = parseInt(bgHex.slice(5, 7), 16);

  // Calculate the resulting RGB components
  const rr = Math.round((1 - a) * br + a * r);
  const rg = Math.round((1 - a) * bg + a * g);
  const rb = Math.round((1 - a) * bb + a * b);

  // Convert the resulting RGB components to hex
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (rr << 16) + (rg << 8) + rb).toString(16).slice(1).toUpperCase()}`;
};

export const convertMinutesToMilliSeconds = minutes => minutes * 60000;
export const convertFromSecondsToMilliseconds = seconds => seconds * 1000;
